/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as o } from "../core/accessorSupport/decorators/subclass.js";
import { owningCollectionProperty as s } from "../core/support/OwningCollection.js";
import t from "./Layer.js";
import { BlendLayer as i } from "./mixins/BlendLayer.js";
import { ScaleRangeLayer as p } from "./mixins/ScaleRangeLayer.js";
import { GraphicsCollection as a } from "../support/GraphicsCollection.js";
import n from "../symbols/support/ElevationInfo.js";
let c = class extends i(p(t)) {
  constructor(r) {
    super(r), this.elevationInfo = null, this.graphics = new a(), this.screenSizePerspectiveEnabled = !0, this.type = "graphics", this.internal = !1;
  }
  destroy() {
    this.removeAll(), this.graphics.destroy();
  }
  add(r) {
    return this.graphics.add(r), this;
  }
  addMany(r) {
    return this.graphics.addMany(r), this;
  }
  removeAll() {
    return this.graphics.removeAll(), this;
  }
  remove(r) {
    this.graphics.remove(r);
  }
  removeMany(r) {
    this.graphics.removeMany(r);
  }
  on(r, e) {
    return super.on(r, e);
  }
  graphicChanged(r) {
    this.emit("graphic-update", r);
  }
};
r([e({
  type: n
})], c.prototype, "elevationInfo", void 0), r([e(s(a, "graphics"))], c.prototype, "graphics", void 0), r([e({
  type: ["show", "hide"]
})], c.prototype, "listMode", void 0), r([e()], c.prototype, "screenSizePerspectiveEnabled", void 0), r([e({
  readOnly: !0
})], c.prototype, "type", void 0), r([e({
  constructOnly: !0
})], c.prototype, "internal", void 0), c = r([o("esri.layers.GraphicsLayer")], c);
const h = c;
export { h as default };